/** @jsx jsx */
import { jsx, Heading, Button } from "theme-ui"
import HomeLayout from "../components/home-layout"
import { SEO } from "gatsby-theme-swhwsys-core"
import { navigate } from "gatsby"

const Level1Services = () => (
	<HomeLayout>
		<SEO title="Level 1 Services" />

    <Heading as="h1"
      sx={{
        // textAlign: "center",
        mb: 2,
      }}
    >Level 1 Services</Heading>

    <Heading as="h2"
      sx={{
        // textAlign: "center",
        mb: 2,
      }}>
      Validate IoT Solution Value Proposition
    </Heading>

		<ul>
			<li>Collaborate with leadership and management</li>
			<li>In depth exploration of IoT Solution contribution to business value</li>
      <ul>
        <li>IoT Device operational integrations</li>
        <li>Proactive business decision opportunities based on IoT device data</li>
        <li>Identify initial IoT Solution features</li>
      </ul>
			<li>Deliverable</li>
      <ul>
        <li>Internal/Public IoT Solution vision statement and value proposition</li>
        <li>Prioritized Epic work items with business and relative effort values</li>
      </ul>
		</ul>

		<div sx={{ display: "grid", placeItems: "center" }}>
        <Button
          onClick={() => navigate(-1)}
          sx={{
            backgroundImage: (theme) =>
              `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.accent} 0%, ${theme.colors.highlight} 90% )`,
            color: "inherit",
            fontWeight: "bold",
            boxShadow: "default",
            transition: "all 0.3s ease",
            ":hover, :focus, :active": {
              boxShadow: "lg",
              backgroundImage: (theme) =>
                `radial-gradient( circle farthest-corner at 10% 20%,  ${theme.colors.highlight} 0%, ${theme.colors.accent} 90% )`,
            },
          }}
        >
          Back
        </Button>
      </div>

	</HomeLayout>
);

export default Level1Services;
